var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header-slot',{scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('b-button',{attrs:{"variant":"primary","to":{ name: 'list-programs-social-network' }}},[_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon"}}),_vm._v(" RETURN")],1)]},proxy:true}])}),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-row',[_c('b-col',{attrs:{"xl":"6","lg":"6","md":"12","sm":"12","xs":"12","cols":"12"}},[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"cols":"5"}},[_c('validation-provider',{attrs:{"name":"Provider","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors[0] ? 'is-invalid' : '',attrs:{"label":"Source","label-class":"font-weight-bolder"}},[_c('v-select',{attrs:{"options":_vm.optionsSources},model:{value:(_vm.filterSource),callback:function ($$v) {_vm.filterSource=$$v},expression:"filterSource"}}),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"5"}},[_c('validation-provider',{attrs:{"name":"Provider","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors[0] ? 'is-invalid' : '',attrs:{"label":"Contact Method","label-class":"font-weight-bolder"}},[_c('v-select',{attrs:{"options":_vm.optionMethods},model:{value:(_vm.filterMethod),callback:function ($$v) {_vm.filterMethod=$$v},expression:"filterMethod"}}),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)})],1),_c('b-col',{staticStyle:{"margin-top":"1.8rem","padding-left":"0 !important"},attrs:{"cols":"2"}},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-button',{attrs:{"variant":"primary","disabled":invalid},on:{"click":_vm.getFlyers}},[_vm._v("Search")])],1)])],1)],1)],1),_vm._l((_vm.indicators),function(indicator,index){return _c('b-col',{key:index,attrs:{"xl":"2","lg":"2","md":"4","sm":"4","cols":"4"}},[_c('b-card',{staticClass:"card",style:("border: 1px solid " + indicator.color + " !important"),attrs:{"no-body":""}},[_c('b-card-body',[_c('div',{staticClass:"d-flex align-items-end justify-content-between cursor-pointer"},[_c('span',{staticClass:"title text-uppercase"},[_vm._v(" "+_vm._s(indicator.title)+" ")]),_c('b-avatar',{staticClass:"text-primary",attrs:{"variant":_vm.isDarkTheme ? 'secondary' : 'light',"size":"3em"}},[_c('feather-icon',{attrs:{"size":"20","icon":indicator.icon}})],1)],1)]),_c('div',{staticClass:"d-inline-flex cursor-pointer",style:("border-top: 1px solid " +
              indicator.color +
              " !important;border-bottom: 1px solid " +
              indicator.color +
              " !important")},[_c('div',{staticClass:"detail-card",style:("border-right: 1px solid " + indicator.color + " !important;")},[_c('span',[_vm._v(" TOTAL: ")]),_c('span',[_vm._v(" "+_vm._s(indicator.total)+" ")])]),_c('div',{staticClass:"detail-card",style:("border-right: 1px solid " + indicator.color + " !important;")},[_c('span',[_vm._v(" SUBTOTAL: ")]),_c('span',[_vm._v(" "+_vm._s(indicator.subtotal)+" ")])])])],1)],1)})],2)]}}])}),_c('filter-slot',{attrs:{"filter":_vm.filters,"filter-principal":_vm.filterPrincipal,"total-rows":_vm.totalRows,"paginate":_vm.paginate,"start-page":_vm.startPage,"to-page":_vm.toPage},on:{"reset-all-filters":_vm.resetAllFilters,"reload":_vm.auxReloadFilter,"onChangeCurrentPage":_vm.getFlyers},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('div',{staticClass:"mx-2"},[(!_vm.loading)?_c('TableCard',{attrs:{"items":_vm.items}}):_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1 custom-spinner"}),_c('strong',[_vm._v("Loading ...")])],1)],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
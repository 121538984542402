export default [
  {
    type: 'datepicker',
    margin: true,
    showLabel: true,
    label: 'Publication From:',
    placeholder: 'Date',
    class: 'font-small-3',
    model: null,
    locale: 'en',
    dateFormatOptions: { year: 'numeric', month: 'numeric', day: 'numeric' },
    cols: 6,
  },
  {
    type: 'datepicker',
    margin: true,
    showLabel: true,
    label: 'Publication To:',
    placeholder: 'Date',
    class: 'font-small-3',
    model: null,
    locale: 'en',
    dateFormatOptions: { year: 'numeric', month: 'numeric', day: 'numeric' },
    cols: 6,
  },
  {
    type: "input-masked",
    margin: true,
    showLabel: true,
    label: "Replies From:",
    placeholder: "Initial Range",
    vMask: "#######",
    model: null,
    cols: 6
  },
  {
    type: "input-masked",
    margin: true,
    showLabel: true,
    label: "Replies To:",
    model: null,
    placeholder: "Initial Range",
    vMask: "#######",
    cols: 6
  },
  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'Order By:',
    model: null,
    options: [
      { value: 1, label: 'Publication Date' },
      { value: 2, label: 'Number Replies' },
    ],
    reduce: 'value',
    selectText: 'label',
    cols: 6,
  },
  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'Order Type:',
    model: null,
    options: [
      { value: 1, label: 'ASC' },
      { value: 2, label: 'DESC' },
    ],
    reduce: 'value',
    selectText: 'label',
    cols: 6,
  }
]


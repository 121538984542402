<template>
  <div>
    <b-row v-if="items.length >= 1">
      <b-col
        xl="3"
        lg="4"
        md="4"
        sm="6"
        xs="12"
        v-for="item in items"
        v-bind:key="item.id"
        class="mb-2"
      >
        <div class="mx-auto flyer-card" @click="redirectToFlyerDetail(item.id)">
          <div class="container-image">
            <b-avatar
              square
              :src="item.route_thumb"
              class="card-image"
              icon="file-earmark-image-fill"
              variant="light"
            >
            </b-avatar>
          </div>

          <div class="flyer-content text-center">
            <div class="flyer-name mt-1">
              {{ item.flyer_name | limitChars(15) }}
            </div>
            <b-row>
              <b-col
                cols="6"
              >
                <div class="item indicator-container">
                  <div class="d-inline">
                    <p class="indicator">
                      {{ item.nreplies }}
                    </p>
                  </div>
                  <div class="indicator-content">
                    <feather-icon icon="CornerUpRightIcon"></feather-icon>
                    <p>Replies</p>
                  </div>
                </div>
              </b-col>
              <b-col
                cols="6"
              >
                <div class="item indicator-container">
                  <div class="d-inline">
                    <p class="indicator">
                      {{ item.nleads }}
                    </p>
                  </div>
                  <div class="indicator-content">
                    <feather-icon icon="UsersIcon"></feather-icon>
                    <p>Leads</p>
                  </div>
                </div>
              </b-col>
              <b-col cols="12 text-center">
                <hr />
                <div class="flyer-description">
                  <b style="font-weight: bold 500 !important">
                    Publication date:
                  </b>
                  <p>
                    <feather-icon icon="CalendarIcon"></feather-icon>
                    {{ item.publication_date | myGlobal }}
                  </p>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
    </b-row>
    <div v-else class="text-center">
      <p>No matching records found</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    items: {
      type: Array,
      required: true,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {};
  },

  methods: {
    redirectToFlyerDetail(flyerName) {
      const program = this.$route.params.program;
      localStorage.setItem("currentSource", this.items[0].source_id);
      localStorage.setItem("currentMethod", this.items[0].contact_method_id);
      this.$router.push({
        name: "detail-by-flyer-social-network",
        params: {
          program: program,
          flyer: flyerName,
        },
      });
    },
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    isDarkTheme() {
      return this.$store.state.appConfig.layout.skin == "dark";
    },
  },
};
</script>

<style scoped lang="scss">
.card-container {
  -webkit-perspective: 1000;
  perspective: 1000;
}

.flyer-card {
  max-width: 300px;
  border-radius: 5px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  cursor: pointer;
  background-color: #ffffff;
}

.indicator {
  font-size: 1.4rem;
  font-weight: bold 400 !important;
  margin-right: 3px;
  display: inline-block;
  color: black;
}

.indicator-content {
  display: inline-block;
  color: #777;
}

.indicator-container {
  padding-top: 4px;
  background-color: #d9eefb;
  color: #ffffff !important;
  border-radius: 10px;
}

.dark-layout {
  .flyer-card {
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
    background-color: #2f2f32 !important;
  }
  .indicator-container {
    background-color: #454648;
  }
  .indicator-content {
    color: #ffffff;
    opacity: 0.7;
  }
  .indicator {
    color: #ffffff !important;
    font-weight: bold;
    opacity: 0.9;
  }
}

.flyer-content {
  position: relative;
  padding: 10px 15px 0 15px;
}

.flyer-name {
  font-weight: bold;
  padding: 5px;
  position: absolute;
  left: 3%;
  right: 3%;
  top: -48px;
  color: #fff;
  font-size: 17px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 0 0 10px 10px;
}

.flyer-name p {
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 1.5px;
}

.flyer-overview p {
  font-size: 10px;
  font-weight: 600;
}

.container-image {
  width: 94%;
  margin: 0 auto;
  padding: 10px 0 0 0;
}
.card-image {
  /* filter: brightness(30%); */
  object-fit: cover;
  width: 100%;
  height: 200px;
  border-radius: 0 0 12px 12px !important;
}

.card-status {
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
}

.item {
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
<template>
  <div>
    <header-slot>
      <template #actions>
        <b-button
          variant="primary"
          :to="{ name: 'list-programs-social-network' }"
          ><feather-icon icon="ChevronLeftIcon"></feather-icon> RETURN</b-button
        >
      </template></header-slot
    >

    <validation-observer #default="{ invalid }">
      <b-row>
        <b-col xl="6" lg="6" md="12" sm="12" xs="12" cols="12">
          <b-card>
            <b-row>
              <b-col cols="5">
                <validation-provider
                  #default="{ errors }"
                  name="Provider"
                  rules="required"
                >
                  <b-form-group
                    label="Source"
                    label-class="font-weight-bolder"
                    :class="errors[0] ? 'is-invalid' : ''"
                  >
                    <v-select
                      :options="optionsSources"
                      v-model="filterSource"
                    />
                    <span v-if="errors[0]" class="text-danger">
                      {{ errors[0] }}</span
                    >
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="5">
                <validation-provider
                  #default="{ errors }"
                  name="Provider"
                  rules="required"
                >
                  <b-form-group
                    label="Contact Method"
                    label-class="font-weight-bolder"
                    :class="errors[0] ? 'is-invalid' : ''"
                  >
                    <v-select :options="optionMethods" v-model="filterMethod" />
                    <span v-if="errors[0]" class="text-danger">
                      {{ errors[0] }}</span
                    >
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                cols="2"
                style="margin-top: 1.8rem; padding-left: 0 !important"
              >
                <div class="d-flex justify-content-center">
                  <b-button
                    variant="primary"
                    @click="getFlyers"
                    :disabled="invalid"
                    >Search</b-button
                  >
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col
          xl="2"
          lg="2"
          md="4"
          sm="4"
          cols="4"
          v-for="(indicator, index) in indicators"
          v-bind:key="index"
        >
          <b-card
            no-body
            :style="`border: 1px solid ` + indicator.color + ` !important`"
            class="card"
          >
            <b-card-body>
              <div
                class="d-flex align-items-end justify-content-between cursor-pointer"
              >
                <span class="title text-uppercase">
                  {{ indicator.title }}
                </span>
                <b-avatar
                  class="text-primary"
                  :variant="isDarkTheme ? 'secondary' : 'light'"
                  size="3em"
                >
                  <feather-icon size="20" :icon="indicator.icon" />
                </b-avatar>
              </div>
            </b-card-body>

            <div
              class="d-inline-flex cursor-pointer"
              :style="
                `border-top: 1px solid ` +
                indicator.color +
                ` !important;border-bottom: 1px solid ` +
                indicator.color +
                ` !important`
              "
            >
              <div
                class="detail-card"
                :style="
                  `border-right: 1px solid ` + indicator.color + ` !important;`
                "
              >
                <span> TOTAL: </span>
                <span>
                  {{ indicator.total }}
                </span>
              </div>
              <div
                class="detail-card"
                :style="
                  `border-right: 1px solid ` + indicator.color + ` !important;`
                "
              >
                <span> SUBTOTAL: </span>
                <span>
                  {{ indicator.subtotal }}
                </span>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </validation-observer>
    <filter-slot
      :filter="filters"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reset-all-filters="resetAllFilters"
      @reload="auxReloadFilter"
      @onChangeCurrentPage="getFlyers"
    >
      <template #table>
        <div class="mx-2">
          <TableCard v-if="!loading" :items="items" />
          <div v-else class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1 custom-spinner" />
            <strong>Loading ...</strong>
          </div>
        </div>
      </template>
    </filter-slot>
  </div>
</template>
  
<script>
import dataFilter from "@/views/social-network/views/statistics/method-contact/data/filters.data";
import TableCard from "@/views/social-network/views/statistics/method-contact/components/TableCard.vue";
import StatisTicsService from "@/views/social-network/views/statistics/services/statistics.service";
import SettingService from "@/views/social-network/views/settings/settings.service.js";
import { mapGetters } from "vuex";
export default {
  components: {
    TableCard,
  },
  data() {
    return {
      loading: false,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      totalRows: 0,
      filters: dataFilter,
      paginate: {
        currentPage: 1,
        perPage: 50,
      },
      startPage: 0,
      toPage: 0,

      indicators: [
        {
          title: "TOTAL LEADS:",
          icon: "UsersIcon",
          total: 0,
          subtotal: 0,
          color: "#0090E7",
        },
        {
          title: "TOTAL REPLIES:",
          icon: "CornerUpRightIcon",
          total: 0,
          subtotal: 0,
          color: "#0090E7",
        },
        {
          title: "TOTAL CRM APPOINTMENTS:",
          icon: "CalendarIcon",
          total: 0,
          subtotal: 0,
          color: "#0090E7",
        },
      ],
      prevReload: 0,
      program: this.$route.params.program,
      items: [],
      optionMethods: [],
      optionsSources: [],
      filterSource: "",
      filterMethod: "",
      sortBy: "created_at",
    };
  },

  methods: {
    resetAllFilters() {
      this.filters.forEach((filter) => {
        filter.model = null;
      });
      this.filterPrincipal.model = null;
    },

    async getFlyers() {
      try {
        this.loading = true;
        const params = {
          search_text: this.filterPrincipal.model,
          from: this.filters[0].model,
          to: this.filters[1].model,
          perpage: this.paginate.perPage,
          page: this.paginate.currentPage,
          replice_from: this.filters[2].model,
          replice_to: this.filters[3].model,
          order_by: this.filters[4].model ?? 2,
          order: this.filters[5].model ?? 2,
          program: this.program,
          source: this.filterSource.value,
          methodContact: this.filterMethod.value,
        };
        const { data } = await StatisTicsService.getFlyersByProgramAndMethod(
          params
        );
        if (data.data.length > 0) {
          this.calcTotals(data.data, true);
        } else {
          this.calcTotals(0, false);
        }
        this.items = data.data;
        this.startPage = data.from ? data.from : 0;
        this.paginate.perPage = data.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = data.last_page;
        this.totalRows = data.total;
        this.toPage = data.to ? data.to : 0;
      } catch (e) {
        this.showErrorSwal(e);
        this.loading = false;
        return [];
      } finally {
        this.loading = false;
      }
    },

    async getSourceMethods() {
      this.addPreloader();
      const params = {
        program: this.program,
      };
      const { data } = await SettingService.getSourceMethodsByProgram(params);
      if (data.length > 0) {
        this.optionsSources = data;
        this.filterSource = data[0];
        this.optionMethods = data[0].methods;
        this.filterMethod = data[0].methods[0];
        this.prevReload = 1;
        this.removePreloader();
        this.getFlyers();
      }
      this.removePreloader();
    },

    async auxReloadFilter() {
      if (this.prevReload == 1) {
        this.getFlyers();
      }
    },

    calcTotals(data, option) {
      if (option) {
        this.indicators[0].subtotal = 0;
        this.indicators[1].subtotal = 0;
        this.indicators[2].subtotal = 0;

        this.indicators[0].total = data[0].total_leads;
        this.indicators[1].total = data[0].total_replies;
        this.indicators[2].total = data[0].total_appointments;

        data.forEach((element) => {
          this.indicators[0].subtotal += Number(element.nleads);
          this.indicators[1].subtotal += Number(element.nreplies);
          this.indicators[2].subtotal += element.nappo;
        });
      } else {
        this.indicators[0].subtotal = 0;
        this.indicators[1].subtotal = 0;
        this.indicators[2].subtotal = 0;

        this.indicators[0].total = 0;
        this.indicators[1].total = 0;
        this.indicators[2].total = 0;
      }
    },
  },

  created() {
    this.getSourceMethods();
  },

  watch: {
    filterSource(newValue) {
      if (newValue != null) {
        this.filterMethod = newValue.methods[0];
        this.optionMethods = newValue.methods;
      } else {
        this.optionMethods = [];
        this.filterMethod = [];
      }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    isDarkTheme() {
      return this.$store.state.appConfig.layout.skin == "dark";
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.badget-wo-color {
  padding: 3px 6px;
  border-radius: 5px;
  font-weight: bold;
}

.detail-card {
  font-size: 12px;
  width: 50%;
  padding-top: 7px;
  padding-bottom: 7px;
  text-align: center;
}

@media (min-width: 1500px) {
  .detail-card {
    font-size: 12px;
  }
}
</style>
  